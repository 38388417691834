<!--客户列表页-->
<style scoped lang="less">
    .button {
        margin-right: 5px;
    }
    .content{padding:0;}
</style>

<template>
    <div class="content">
        <div id="search_top" class="search-top">
            <div class="input-group-inline">
                <label>账号：</label>
                <Input class="custom-control-width" v-model="queryData.phone" placeholder="请输入账号" style="width: 200px" :maxlength=20></Input>
            </div>
            <div class="input-group-inline">
                <label>公司名称：</label>
                <Input class="custom-control-width" v-model="queryData.company" placeholder="请输入公司名称" style="width: 200px" :maxlength=50></Input>
            </div>
            <div class="input-group-inline right clearfix">
                <Button class="button" type="primary" @click="query" :loading="queryLoading">查询</Button>
                <Button class="button" @click="clear">清空</Button>
                <Button class="button" type="warning" @click="addAccountPoP">新增客户</Button>
            </div>
        </div>
        <Table id="table" size="small" :loading="loading" highlight-row stripe border :height="tableHeight" :columns="columns" :data="userData"></Table>
        <div id="page-box" class="page-box">
            <Page :total="total" :current="current" size="small" :page-size="pageSize" show-total @on-change="changePage"></Page>
        </div>

        <Modal v-model="showAddAccount" @on-cancel="resetFields('addPop')" :title="popTitle">
            <Form ref="addPop" :model="newAccount" :rules="ruleOfNewAccount" :label-width="100">
                <Form-item label="账号" prop="phone">
                    <Input :disabled="isEdit==true" v-model="newAccount.phone" placeholder="请输入账号" :maxlength=11></Input>
                </Form-item>
                <Form-item label="公司名称" prop="company">
                    <Input v-model="newAccount.company" placeholder="请输入公司名称" :maxlength=50></Input>
                </Form-item>
                <!--<Form-item label="请选择客户组" prop="groupId">-->
                    <!--<Select v-model="newAccount.groupId" class="control-width">-->
                        <!--<Option v-for="item in groups" :value="item._id" :key="item._id">{{ item.name }}</Option>-->
                    <!--</Select>-->
                <!--</Form-item>-->
            </Form>
            <div slot="footer">
                <Button type="primary" size="large" :loading="addLoading" @click="addAccount('addPop')">提交</Button>
                <Button type="primary" ghost v-if="!isEdit" size="large" @click="resetFields('addPop')">重置</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
    import {ajax,checkAuth,formateDate} from '../../libs/util';
    import Cookies from 'js-cookie';
    import tableHelper from '../../components/mixins/table-helper.vue';


    export default {
        mixins: [tableHelper],
        data () {
            return {
                total:0,
                loading:true,
                popTitle: '新增客户',
                checkAuth: checkAuth,
                current: 1,
                pageSize:CONFIG.pageSize,
                showAddAccount: false,
                addLoading: false,
                queryLoading:false,
                queryData: {
                    company:'',
                    phone:'',
                },
                isEdit: false,
                newAccount:{
                    phone:'',
                    company:'',
                    // groupId: '',
                },
                ruleOfNewAccount: {
                    phone: [
                        { required: true, message: '请输入账号', trigger: 'blur' },
                    ],
                    company: [
                        { required: true, message: '请输入公司名称', trigger: 'blur' }
                    ],
                    // groupId: [
                    //     { required: true, message: '请选择客户组', trigger: 'blur' }
                    // ],
                },
                columns: [{
                    type: 'index',
                    title: '序号',
                    width: 70,
                    align: 'center'
                }, {
                    title: '操作',
                    key: 'action',
                    width: 70,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Poptip', {
                                props: {
                                    trigger: "hover",
                                    placement: "right",
                                }
                            },[
                                h('Icon',{
                                    props: {
                                        type: 'md-settings',
                                        size: '20'
                                    }
                                }),
                                h('div',{
                                    slot:'content'
                                }, [
                                    h('ButtonGroup',{
                                        props:{
                                            vertical: true
                                        }
                                    },[
                                        h('Button', {
                                            props: {
                                                type: 'text',
                                            },
                                            on: {
                                                click: () => {
                                                    this.edit(params.row)
                                                }
                                            }
                                        }, '编辑'),
                                        h('Button', {
                                            props: {
                                                type: 'text',
                                            },
                                            on: {
                                                click: () => {
                                                    this.initPassword(params.row)
                                                }
                                            }
                                        }, '重置密码')
                                        // h('Button', {
                                        //     props: {
                                        //         type: 'text',
                                        //     },
                                        //     on: {
                                        //         click: () => {
                                        //             this.remove(params.row);
                                        //         }
                                        //     }
                                        // }, '删除')
                                    ]),
                                ]),
                            ])
                        ])
                    }
                }, {
                    title: '账号',
                    key: 'phone',
                }, {
                    title: '公司名称',
                    key: 'company',
                }, {
                    title: '设备总数',
                    key: 'deviceCount',

                }, {
                    title: '创建时间',
                    key: 'created',
                    render:(h,params) => {
                        return h('span',formateDate(params.row.created*1000))
                    }
                }],
                userData: [],
                groups: [],
            }
        },
        methods: {
            query(){
                // this.$Message.info('暂不支持客户查询,敬请期待'); ?name='+this.queryData.name+'&userName='+this.queryData.userName
                this.queryLoading = true;
                ajax.post('/customer/list',{
                    company:this.queryData.company,
                    phone:this.queryData.phone,
                    page:1,
                    pageSize:this.pageSize
                },(res)=> {
                    this.userData = res.data.data;
                    this.total = res.data.total;
                    this.queryLoading = false;
                },(error)=> {
                    this.$Message.error(error.message || "服务器异常，请重试");
                    this.queryLoading = false;
                });
            },
            clear(){
                this.queryData = {
                    company:'',
                    phone:'',
                }
            },
            addAccountPoP(){
                this.popTitle = "新增客户";
                this.isEdit = false;
                this.showAddAccount = true;
            },
            addAccount(value){
                if (!this.isEdit){
                    this.$refs[value].validate((valid) => {
                        if (valid) {
                            this.addLoading  = true;
                            let pt = this.randomPassword(8)
                            ajax.post('/customer/add',{
                                phone: this.newAccount.phone,
                                company: this.newAccount.company,
                                password: pt,
                            },(res)=> {
                                this.$Modal.success({
                                    title: '新增客户成功',
                                    content: `账号：<span class="orange">${this.newAccount.phone}</span><br>密码：<span class="orange">${pt}</span>`
                                });
                                this.fetchList(this.current);
                                this.resetFields('addPop');
                                this.showAddAccount = false;
                            },(error)=> {
                                this.addLoading  = false;
                                this.$Message.error(error.message || "服务器异常，请重试");
                            });
                        }
                    });
                }else{
                    this.editSave();
                }
            },
            resetFields(value) { // 重置数据
                this.addLoading = false;
                this.newAccount = {
                    phone:'',
                    company:'',
                };
                this.$refs[value].resetFields();
            },
            edit(row){
                this.popTitle = "编辑客户";
                this.isEdit = true;
                this.newAccount.company = row.company;
                this.newAccount.phone = row.phone;
                this.newAccount.id = row.id;
                this.showAddAccount = true;
                // 遍历客户组id是否存在
                // this.groups.forEach((item)=>{
                //     if(item._id == row.groupId){
                //         this.newAccount.groupId = row.groupId;
                //     }
                // });
                // if(!row.groupName) {
                //     this.newAccount.groupId = '';
                // }
                // if (this.newAccount._id == Cookies.get("userId")){
                //     this.$Modal.confirm({
                //         title: '操作提示',
                //         content: '编辑当前登录账号之后，将退出登录，确认编辑么？',
                //         okText: '确认',
                //         onOk: () => {
                //             this.showAddAccount = true;
                //         }
                //     });
                // }else{
                //     this.showAddAccount = true;
                // }
            },
            editSave () { // 编辑账户，只有姓名和所属客户组可以修改，其他内容不允许修改
                if(!this.newAccount.company) {
                    this.$Message.info('必填项不能为空');
                    return;
                }
                this.addLoading  = true;
                ajax.post('/customer/edit',{
                    company: this.newAccount.company,
                    uid: this.newAccount.id,
                },(res)=>{
                    this.$Message.success('编辑客户成功');
                    this.fetchList(this.current);
                    this.resetFields('addPop');
                    this.showAddAccount = false;
                },(err)=>{
                    this.addLoading  = false;
                    this.$Message.error(err.message || "服务器异常，请重试");
                })
            },
            initPassword (value) {
                let newPassword = this.randomPassword(8);
                let id = value.id;
                this.$Modal.confirm({
                    title: '操作提示',
                    content: `确认重置账号${value.company}的密码吗？`,
                    okText: '确认',
                    onOk: () => {
                        ajax.post(`/customer/reset_password`,{
                            uid:id,
                            newPassword:newPassword,
                        },(res)=> {
                            setTimeout(()=>{
                                this.showNewPassword(newPassword);
                            },500);
                        },(error)=> {
                            this.$Message.error(error.message || "服务器异常，请重试");
                        });
                    }
                });
            },
            showNewPassword(pt) {
                this.$Modal.success({
                    title: '重置密码成功',
                    content: `新密码为：<span class="orange">${pt}</span>`,
                });
            },

            changePage (page) {
                this.current = page;
                this.fetchList(page);
                document.getElementsByClassName('ivu-table-body')[0].scrollTop = 0;
            },
            fetchList(page) {
                let p = page || 1;
                this.loading = true;
                ajax.post('/customer/list',{
                    company:this.queryData.company,
                    phone:this.queryData.phone,
                    page:p,
                    pageSize:this.pageSize
                },(res)=> {
                    this.userData = res.data.data;
                    this.total = res.data.total;
                    this.loading = false;
                },(error)=> {
                    this.$Message.error(error.message || "服务器异常，请重试");
                    this.loading = false;
                });
            },
            // fetchGroupList() {  // 获取客户组列表
            //     ajax.get('/userGroup',(res)=> {
            //         this.groups = res.data.list;
            //     },(error)=> {
            //         this.$Message.error(error.message || "服务器异常，请重试");
            //     });
            // },
            randomPassword(size) {
                var seed = new Array('A','B','C','D','E','F','G','H','I','J','K','L','M','N','P','Q','R','S','T','U','V','W','X','Y','Z','a','b','c','d','e','f','g','h','i','j','k','m','n','p','Q','r','s','t','u','v','w','x','y','z','2','3','4','5','6','7','8','9');//数组
                var seedlength = seed.length;//数组长度
                var createPassword = '';
                for (var i=0;i<size;i++) {
                    var j = Math.floor(Math.random()*seedlength);
                    createPassword += seed[j];
                }
                return createPassword;
            }
        },
        mounted() {
            this.fetchList();
            // this.fetchGroupList();
        }
    }
</script>
